'use client'

import { GrabSlider } from "@/core/helpers/helper";
import { useEffect } from "react";


export default function MediaClient({ article, articleMobile, children }) {

    useEffect(() => {
        if (document.getElementById("media-bottom-slider") && articleMobile?.[0]?.length > 0) GrabSlider("media-bottom-slider");
        if (document.getElementById("media-top-slider") && articleMobile?.[1]?.length > 0) GrabSlider("media-top-slider");
    }, [articleMobile]);

    useEffect(() => {
        if (document.getElementById("media-slider") && article?.length > 0) GrabSlider("media-slider");
    }, [article]);

    return children;
}
