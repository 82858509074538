'use client'

import { GrabSlider } from "@/core/helpers/helper";
import { useEffect } from "react";


export default function DeveloperPartnersClient({ children, developer, developerMobile }) {

    useEffect(() => {
        if (document.getElementById('developer-bottom-slider') && developerMobile?.[0]?.length > 0) GrabSlider("developer-bottom-slider");
        if (document.getElementById('developer-top-slider') && developerMobile?.[1]?.length > 0) GrabSlider("developer-top-slider");
    }, [developerMobile]);

    useEffect(() => {
        if (document.getElementById('developer-slider') && developer?.length > 0) GrabSlider("developer-slider");
    }, [developer]);

    return children;
}

