'use client'

import { useEffect } from "react";

import { GrabSlider } from "@/core/helpers/helper";

export default function ComparisonClient({ children }) {
  useEffect(() => {
    GrabSlider('table-slider');
  }, []);

  return children;
}
