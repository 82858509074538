'use client'

import { useEffect } from "react";

// Import Custom Library
import { GrabSlider } from "@/core/helpers/helper";

export default function ListBank({ bank = [], children }) {

    useEffect(() => {
        if (bank?.length > 0) GrabSlider("bank-slider");
    }, [bank]);

    return children
}
