"use client";

// Import Custom Library
import { store } from '@/core/store';

// Import Component
import NotFoundPage from "@/app/(root)/not-found-page";
import ReduxProvider from '@/core/components/ReduxProvider';

export default function NotFound({ enableIdealPay, helpContent, listFAQGroup }) {
    return (
        <ReduxProvider store={store}>
            <NotFoundPage enableIdealPay={enableIdealPay} helpContent={helpContent} listFAQGroup={listFAQGroup} />
        </ReduxProvider>
    )
}
