// Import Library
import Image from "next/image";
import React from 'react';

/**
 * 
 * @param {{
 * alt: string;
 * className: string;
 * height: number | `${number}`;
 * loading: 'eager' | 'lazy';
 * priority: boolean;
 * quality: number;
 * src: string | StaticImport;
 * width: number | `${number}`;
 * }} props 
 * @returns 
 */

export default function ImageComponent(props) {

    const {
        alt = '',
        className = '',
        height = undefined,
        loading = 'eager',
        priority = false,
        quality = 100,
        src = '',
        width = undefined,
        ...rest
    } = props;

    return (
        <Image
            alt={alt}
            className={className}
            height={height}
            loading={loading}
            priority={priority}
            quality={quality}
            src={src}
            width={width}
            {...rest}
        />
    );
}
