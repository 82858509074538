import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiName: '',
  isError: false,
  isErrorToast: false,
  errorButton: 'Ok',
  errorCode: null,
  errorMessage: '',
  errorTitle: '',
  isErrorServer: false,
  isResponse: true,
  isTimeout: false,
  responseCode: null,
  responseMessage: '',
  responseTitle: '',
  responseAction: {
    message: "",
    status: false,
  },
  responseConfirmation: {
    cancel: null,
    confirm: null,
    message: "",
    status: false,
  },
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    showError: (state, action) => {
      const { error, defaultMessage, defaultTitle, defaultButton } = action.payload;
      const { response } = error;

      let message = '';
      let title = '';
      let errorCode = null;

      if (!defaultTitle && response && typeof response?.statusText !== 'undefined') {
        title = response.statusText;
      } else if (!defaultTitle) {
        title = 'Oops! Sorry, it’s failed.';
      } else if (defaultTitle) {
        title = defaultTitle;
      }

      if (typeof response !== 'undefined') {
        if (response && response?.data?.errors) {
          message = Object.values(response?.data?.errors)[0][0];

          state.isError = true;
        } else if (response && response?.status === 401) {
          if (response?.data?.ExceptionType === 'SignatureHeaderApiTokenException') {
            title = 'Error!'
            message = 'Something went wrong. Please reset your device date time or restart your device!';

            state.isError = true;
          } else {
            message = 'Sesi kamu habis, silahkan login kembali';

            state.isErrorToast = true;
          }
        } else if (response && response?.status === 402) {
          state.isTimeout = true;
        } else if (response && response?.status === 413) {
          message = 'Ukuran file harus kurang dari 5MB';

          state.isErrorToast = true;
        } else if (response && (response?.status === 500 || response?.status === 530)) {
          state.isErrorServer = true;
        } else if (response && response?.status === 408) {
          state.isTimeout = true;
        } else if (response && response?.data?.Message) {
          message = response?.data?.Message;

          state.isError = true;
        } else if (response && response?.data?.message) {
          message = response?.data?.message;

          state.isError = true;
        } else if (message && message !== '') {
          message = defaultMessage;

          state.isError = true;
        }
        errorCode = response?.status;
      } else if (typeof error === 'string') {
        message = error;

        state.isError = true;
      } else if (message && message !== '') {
        message = defaultMessage || 'Sorry request error, please try again.';

        state.isError = true;
      }

      if (error?.code === "ECONNABORTED") {
        state.isTimeout = true;
      }

      state.errorCode = errorCode;
      state.errorMessage = message;
      state.errorTitle = title;
      state.errorButton = defaultButton ?? 'Ok';
    },
    clearError: (state) => {
      state.apiName = '',
      state.isError = false,
      state.isErrorToast = false,
      state.errorButton = 'Ok',
      state.errorCode = null,
      state.errorMessage = '',  
      state.errorTitle = '',
      state.isErrorServer = false,
      state.isTimeout = false
    },
    setError: (state, action) => {
      const { title, message, code = '500' } = action.payload;
      state.isError = true;
      state.errorMessage = message;
      state.errorTitle = title;
      state.errorCode = code;
    },
    setResponseAction: (state, action) => {
      state.responseAction = action.payload;
    },
    setResponseConfirmation: (state, action) => {
      state.responseConfirmation = action.payload;
    },
    showResponse: (state, action) => {
      const { responses, defaultMessage, defaultTitle } = action.payload;
      const { response } = responses;
      let message = '';
      let title = '';
      let errorCode = null;

      if (!defaultTitle && response && typeof response.statusText !== 'undefined') {
        title = response.statusText;
      } else if (!defaultTitle) {
        title = 'Oops! Sorry, it’s failed.';
      } else if (defaultTitle) {
        title = defaultTitle;
      }

      if (typeof response !== 'undefined') {
        if (response && response.status === 500) {
          message = 'A server error occurred';
        } else if (response && response?.data?.Message) {
          message = response?.data?.Message;
        } else if (message && message !== '') {
          message = defaultMessage;
        }
        errorCode = response.status;
      } else if (typeof action.payload === 'string') {
        message = action.payload;
      } else if (message && message !== '') {
        message = defaultMessage;
      }

      if (!message || message === '') {
        message = 'Sorry request error, please try again.';
      }

      state.isResponse = true;
      state.responseCode = errorCode;
      state.responseMessage = message;
      state.responseTitle = title;
    },
    clearResponse: (state) => {
      state.apiName = '',
      state.isResponse = false;
      state.responseMessage = '';
      state.responseTitle = '';
      state.responseCode = null;
      state.responseAction = {
        message: "",
        status: false,
      },
      state.responseConfirmation = {
        cancel: null,
        confirm: null,
        message: "",
        status: false,
      }
    },
    setResponse: (state, action) => {
      const { title, message, code = '500' } = action.payload;
      state.isResponse = true;
      state.responseMessage = message;
      state.responseTitle = title;
      state.responseCode = code;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { showError, clearError, setError, showResponse, clearResponse, setResponse, setResponseAction, setResponseConfirmation } = apiSlice.actions;

export default apiSlice.reducer;

export const getApi = (state) => state.api;
export const getIsError = (state) => state.api.isError;
export const getIsErrorToast = (state) => state.api.isErrorToast;
export const getErrorButton = (state) => state.api.errorButton;
export const getErrorCode = (state) => state.api.errorCode;
export const getErrorTitle = (state) => state.api.errorTitle;
export const getErrorMessage = (state) => state.api.errorMessage;
export const getIsErrorServer = (state) => state.api.isErrorServer;
export const getIsResponse = (state) => state.api.isResponse;
export const getIsTimeout = (state) => state.api.isTimeout;
export const getResponseAction = (state) => state.api.responseAction;
export const getResponseConfirmation = (state) => state.api.responseConfirmation;
export const getResponseCode = (state) => state.api.responseCode;
export const getResponseTitle = (state) => state.api.responseTitle;
export const getResponseMessage = (state) => state.api.responseMessage;