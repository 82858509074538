"use client";

// Import Library
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

// Import Custom Library
import useScrollTracking from "@/core/helpers/useScrollTracking";
import { getIsLogin } from '@/core/store/reducer/authSlice';

// Import Component
import Button from '@/core/components/Button';

const LayoutAuth = dynamic(() => import('@/components/layout/Auth'), { ssr: false });
const LayoutUser = dynamic(() => import('@/components/layout/User'), { ssr: false });

function Content() {

    return (
        <main className='bg-neutral-10 flex items-center justify-center min-h-screen w-screen'>
            <div className='bg-white border-neutral-30 border-[1px] flex flex-col items-center max-w-[840px] mx-auto p-16 rounded-[16px] text-center w-full'>
                <img src="/images/icons/IconMissingLink.webp" alt="404" height={256} width={256} />
                <p className="leading-6 max-w-[320px] mt-16 text-[18px] text-neutral-90 tracking-[0.25px]">Waduh! tujuan mu tidak ditemukan</p>
                <p className='max-w-[320px] mt-4 text-sm text-neutral-60 tracking-[0.25px]'>Periksa kembali linknya atau memang halaman ini sudah tidak aktif</p>
                <Button
                    path={'/'}
                    loading={false}
                    label={`Homepage`}
                    className="mt-8 min-w-[320px]"
                    buttonProps={{ type: 'button' }}
                    variant={'secondary'}
                />
            </div>
        </main>
    )
}

export default function NotFoundPage({ enableIdealPay, helpContent, listFAQGroup }) {
    useScrollTracking();

    // Check auth
    const isLoggedIn = useSelector(getIsLogin);

    if (isLoggedIn) {
        return (
            <LayoutUser enableIdealPay={enableIdealPay} helpContent={helpContent} listFAQGroup={listFAQGroup} >
                <Content />
            </LayoutUser>
        )
    } else {
        return (
            <LayoutAuth>
                <div className='mt-[-160px] mb-[-24px]'>
                    <Content />
                </div>
            </LayoutAuth>
        )
    }
}
