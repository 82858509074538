'use client'

import { useEffect } from "react";

/**
 * pageview
 */
export const pageview = () => {
  if (typeof window?.gtag !== 'undefined') {
    window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_KEY, {
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_title: document.title ?? 'IDEAL Indonesia'
    });
  }
}

/**
 * logEvent
 */
export const logEvent = (name, data = {}) => {
  if (typeof window?.gtag !== 'undefined') {
    window.gtag('event', name, data);
  }
}

export const logEventNudges = (name, data = {}) => {
  if (+localStorage.getItem(process.env.NEXT_PUBLIC_SMARTECH_STORAGE_NAME) === 1) {
    if (typeof smartech === 'function') {
      // eslint-disable-next-line no-undef
      smartech('dispatch', name, data);
    }
  }
}

/**
 * logAction
 */
export const logAction = (name, data = {}, type = 'Btn', prefix = '') => {
  logEvent(`${prefix}${name}_${type}_A`, data);
}

export const logActionMobile = ({ name, data = {}, type = 'Btn', device = null }) => {
  if (device) logEvent(`${device?.toLowerCase() === 'android' ? 'AND' : 'IOS'}_${name}_${type}_A`, data);
}

/**
 * useLogScreen
 */
export const useLogScreen = (name, data = {}, prefix = '') => {
  useEffect(() => {
    logEvent(`${prefix}${name}`, data);
  }, []);
}

export const useLogScreenMobile = ({ name, data = {}, device = '', isMobile = true }) => {
  useEffect(() => {
    if (device && isMobile) logEvent(`${device?.toLowerCase() === 'android' ? 'AND' : 'IOS'}_${name}`, data);
    else logEvent(name, data);
  }, [isMobile]);
}