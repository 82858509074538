'use client'

// Import Library
import dynamic from "next/dynamic";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { usePathname, useSearchParams } from "next/navigation";
import { logEvent, pageview } from '@/core/helpers/analytics';

// Import Custom Library
import Api from "@/configs/Api";
import useRequest from "@/core/api/useRequest";
import { setPropertyCategory } from "@/core/store/reducer/kompasSlice";
import { clearError, clearResponse } from "@/core/store/reducer/apiSlice";
import { setLoginSource } from "@/core/store/reducer/authSlice";

const ErrorMessage = dynamic(() => import('@/core/components/Modal/ErrorMessage'), { ssr: false });
const Nudge = dynamic(() => import("@/components/Nudge"), { ssr: false });
const PushNotif = dynamic(() => import("@/components/push-notif"), { ssr: false });
const ResponseAction = dynamic(() => import('@/core/components/Modal/ResponseAction'), { ssr: false });
const ResponseConfirmation = dynamic(() => import("@/core/components/Modal/ResponseConfirmation"), { ssr: false });

export default function RootClientPage({ ...props }) {

    const dispatch = useDispatch();

    const pathname = usePathname();
    const searchParams = useSearchParams();

    const { requestPost } = useRequest();

    const refEngagementTime = useRef(null);
    const refTimerPageView = useRef(null);

    const rootPathname = ['/', '/home'];

    useEffect(() => {
        checkUTM();

        const listPropertyCategory = props?.propertyCategory;

        dispatch(setPropertyCategory({
            primary: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'primary'),
            secondary: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'secondary'),
            takeover: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'takeover'),
            multiguna: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'refinance'),
            takeoverMultiguna: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'takeover refinance'),
        }))
    }, []);

    const calculateEngagementTime = () => {
        const currentTime = performance.now();
        return refEngagementTime.current ? Math.round(currentTime - refEngagementTime.current) : 0;
    };

    const onBeforeUnload = () => {
        const engagementTime = calculateEngagementTime();

        localStorage.setItem(process?.env?.NEXT_PUBLIC_STORAGE_NAME, JSON.stringify({
            auth: JSON.parse(localStorage.getItem(process?.env?.NEXT_PUBLIC_STORAGE_NAME))?.auth,
            kompas: JSON.parse(localStorage.getItem(process?.env?.NEXT_PUBLIC_STORAGE_NAME))?.kompas
        }));

        logEvent('user_engagement', {
            'engagement time': engagementTime
        });
    }

    // useEffect(() => {
    //     if (props?.nudgesConfiguration) {
    //         if (+props?.nudgesConfiguration?.listVal1 === 1) {
    //             if (typeof smartech === 'function') {
    //                 // eslint-disable-next-line no-undef
    //                 smartech('create', props?.nudgesConfiguration?.listVal4);
    //                 // eslint-disable-next-line no-undef
    //                 smartech('register', props?.nudgesConfiguration?.listVal5);
    //                 // eslint-disable-next-line no-undef
    //                 smartech('identify', '');
    //                 // eslint-disable-next-line no-undef
    //                 // smartech('debug', '1');
    //             }
    //         }

    //         localStorage.setItem(process.env.NEXT_PUBLIC_SMARTECH_STORAGE_NAME, props?.nudgesConfiguration?.listVal1);
    //     }
    // }, []);

    const isValidVersion = (version) => version?.replace('-', '.')?.split('.')?.every(item => !isNaN(+item));

    // // Check version
    useEffect(() => {
        if (props?.checkVersion && isValidVersion(props.version)) {
            localStorage.setItem(process.env.NEXT_PUBLIC_VERSION_STORAGE_NAME, props.version);

            if (props.checkVersion.forceUpdate) {
                window.location.reload();
            }
        }

        if (!pathname?.includes('/login') && !pathname?.includes('/register')) dispatch(setLoginSource(`${pathname}/${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`));
    }, [pathname, searchParams]);

    useEffect(() => {
        refEngagementTime.current = window.performance.now();

        // user session tracking
        logEvent('session_start');

        // first load page view tracking
        logEvent('page_view', {
            'page location': window.location.href,
            'page referrer': '',
            'engagement time': 0,
        });

        // First visit tracking
        checkFirstVisit();

        // callback function to call when event triggers
        const onPageLoad = () => {
            refTimerPageView.current = setTimeout(() => {
                pageview();
            }, 0);
        };

        // // Check if the page has already loaded
        window.addEventListener('beforeunload', onBeforeUnload);
        window.addEventListener('load', onPageLoad, false);

        return () => {
            clearTimeout(refTimerPageView.current);

            window.removeEventListener('beforeunload', onBeforeUnload);
            window.removeEventListener('load', onPageLoad);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('load', checkFirstLoad);

        return () => {
            window.removeEventListener('load', checkFirstLoad);
        }
    }, []);

    const checkFirstLoad = () => {
        if (!sessionStorage.getItem('firstLoad') || sessionStorage.getItem('firstLoad') === '0') {
            sessionStorage.setItem('firstLoad', '1');
        }
    }

    const checkFirstVisit = async () => {
        dispatch(clearError());
        dispatch(clearResponse());

        const storeVisit = localStorage.getItem('IDEAL_FIRST_VISIT');
        if (!storeVisit) {
            logEvent('first_visit');
            localStorage.setItem('IDEAL_FIRST_VISIT', '1');
        }
    }

    const checkUTM = () => {
        const query = Object.fromEntries(searchParams.entries());

        const params = {
            deviceID: null,
            UTM_UID: query.utm_uid ?? null,
            applicationId: process.env.NEXT_PUBLIC_BASE_URL,
            adNetwork: query.utm_id ?? null,
            source: query.utm_source ?? null,
            medium: query.utm_medium ?? null,
            term: query.utm_term ?? null,
            content: query.utm_content ?? null,
            name: query.utm_campaign ?? null,
            internal: Boolean(query.utm_uid),
            additionalInfo: Object.keys(query).length > 0 ? JSON.stringify(query) : null
        };

        if (rootPathname?.includes(pathname?.toLowerCase()) || params.UTM_UID) {
            requestPost(Api.UTM_TRACKING(), params)
                .then(res => {
                    if (res?.data?.uid) localStorage.setItem('utmUID', res?.data?.uid ?? '');
                })
                .catch(err => console.error('UTM Tracking Error:', err?.response?.data));
        }
    }

    return (
        <Fragment>
            <ErrorMessage />
            <ResponseAction />
            <ResponseConfirmation />
            <PushNotif />
            <Nudge />
        </Fragment>
    )
}
